/* eslint-disable no-param-reassign */
import { createSlice, current, Dispatch } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { keyBy } from 'lodash';
import { TEObject } from '@timeedit/ui-components/lib/src/types/TEObject.type';
import { configService } from '../services/config.service';
import { TFieldsByKind, TMapping, TReservationMode } from 'types/integration.type';
import { ApplicationState } from 'slices';
import TEObjectManager from 'activities/services/TEObjects.service';
import { TField, TReservationMode as TReservationModeTEServer, TTEObject } from '@timeedit/types/lib/types';
import { TReservationTemplate } from '@timeedit/preferences-and-dm-commons/lib/src';

export interface IntegrationState {
  mapping: TMapping;
  objects: Record<string, Record<string, string | string[]>[]>;
  inactiveObjects: Record<string, string[]>;
  objectRelated: Record<string, string[]>;
  objectOptionalRelated: Record<string, string[]>;
  objectsLoading: Record<string, boolean>;
  mappingLoading: boolean;
  fieldsByKind: Record<string, TFieldsByKind>;
  reservationModes: TReservationMode[];
  reservationModesLoading?: boolean;
  reservationFields: Record<string, TField[]>;
  reservationFieldsLoading?: boolean;
  reservationObjectTypes: Record<string, string[]>;
  examConfigFromApi: unknown; // TODO: Better typing once we know what we get
}

const initialState: IntegrationState = {
  mapping: {
    objectTypes: {},
    labeledFields: {},
    fieldLabelsMapping: {},
  },
  objects: {},
  inactiveObjects: {},
  objectRelated: {},
  objectOptionalRelated: {},
  objectsLoading: {},
  mappingLoading: false,
  fieldsByKind: {},
  reservationModes: [],
  reservationModesLoading: undefined,
  reservationFields: {},
  reservationFieldsLoading: undefined,
  reservationObjectTypes: {},
  examConfigFromApi: {},
};

const checkIfReservationModeIsExamV4 = (
  reservationMode: TReservationModeTEServer,
  state: IntegrationState,
): boolean => {
  /*
  TEMP TEMP TEMP
  This is a temporary work-around so that we are not blocked. As of writing this (2024-09-16) it will still take a few weeks before TE Server adds this.
  Till then, we temporarily just check the description of the reservation mode to see if it contains "EXAM_V4".
  TODO: Fix this when TE Server is done
  */
  if (
    reservationMode.description?.includes('EXAM_V4') /* TEMP! See above! */ &&
    // eslint-disable-next-line no-self-compare
    state.examConfigFromApi === state.examConfigFromApi /* TEMP! See above! */
  ) {
    return true;
  }
  return false;
};

const slice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    /* MAPPINGs */
    fetctMappingRequest: (state) => {
      state.mappingLoading = true;
    },
    fetctMappingSuccess: (state, { payload }) => {
      const { mapping, fieldsByKind } = payload;
      state.mappingLoading = false;
      if (mapping) {
        state.mapping = {
          objectTypes: keyBy(mapping.objectTypes, 'applicationObjectTypeName'),
          labeledFields: mapping.objectTypes.reduce((results: any, objectType: any) => {
            return {
              ...results,
              [objectType.applicationObjectTypeName]: (
                objectType.fields.find((field: any) => field.appProperty === 'LABEL') || objectType.fields[0]
              )?.fieldExtId,
            };
          }, {}),
          fieldLabelsMapping: mapping.objectTypes.reduce((results: any, objectType: any) => {
            return {
              ...results,
              [objectType.applicationObjectTypeName]: objectType.fields.reduce((fieldResults: any, field: any) => {
                return {
                  ...fieldResults,
                  [field.fieldExtId]: field.fieldLabel,
                };
              }, {}),
            };
          }, {}),
        };
      }
      if (fieldsByKind) {
        state.fieldsByKind = keyBy(fieldsByKind, 'field');
      }
    },
    fetctMappingFailure: (state) => {
      state.mappingLoading = false;
    },

    fetchReservationModesRequest: (state) => {
      state.reservationModesLoading = false;
    },
    fetchReservationModesSuccess: (state, { payload }) => {
      state.reservationModesLoading = false;
      state.reservationModes = (payload.results || []).map(
        (item: TReservationModeTEServer): TReservationMode => ({
          id: item.id,
          extId: item.extId,
          name: item.name,
          description: item.description,
          reservationTemplates: item.reservationTemplates,
          isExamV4ReservationMode: checkIfReservationModeIsExamV4(item, current(state)),
        }),
      );
    },
    fetchReservationModesFailure: (state) => {
      state.reservationModesLoading = false;
    },

    fetchReservationFieldsRequest: (state) => {
      state.reservationFieldsLoading = true;
    },
    fetchReservationFieldsSuccess: (state, { payload }) => {
      state.reservationFieldsLoading = false;
      state.reservationFields = {
        ...state.reservationFields,
        [payload.mode]: payload.fields as TField[],
      };
      state.reservationObjectTypes = {
        ...state.reservationObjectTypes,
        [payload.mode]: payload.reservationObjectTypes,
      };
    },
    fetchReservationFieldsFailure: (state) => {
      state.reservationFieldsLoading = false;
    },

    fetchExamConfigFromApiRequest: () => {},
    fetchExamConfigFromApiSuccess: (state, { payload }) => {
      state.examConfigFromApi = payload;
    },
    fetchExamConfigFromApiFailure: () => {},

    /* OBJECTSs */
    fetchTEObjectsRequest: (state, { payload }) => {
      state.objectsLoading = {
        ...state.objectsLoading,
        [payload.objectType]: true,
      };
    },
    fetchTEObjectsSuccess: (state, { payload }) => {
      const objects: TEObject[] = [];
      const inactiveObjects: string[] = [];
      const objectRelated: Record<string, string[]> = {};
      const objectOptionalRelated: Record<string, string[]> = {};
      payload.objects.forEach((obj: any) => {
        objects.push(obj.fields);
        if (obj.fields.te_extid) {
          objectRelated[obj.fields.te_extid] = obj.related;
          objectOptionalRelated[obj.fields.te_extid] = obj.optionalRelated;
        }
      });

      (payload.inactiveObjects || []).forEach((obj: any) => {
        objects.push(obj.fields);
        if (obj.fields.te_extid) {
          inactiveObjects.push(obj.fields.te_extid);
          objectRelated[obj.fields.te_extid] = obj.related;
          objectOptionalRelated[obj.fields.te_extid] = obj.optionalRelated;
        }
      });

      state.objects = {
        ...state.objects,
        [payload.type]: objects,
      };
      state.inactiveObjects = {
        ...state.inactiveObjects,
        [payload.type]: inactiveObjects,
      };
      state.objectsLoading = {
        ...state.objectsLoading,
        [payload.type]: false,
      };

      state.objectRelated = {
        ...state.objectRelated,
        ...objectRelated,
      };
      state.objectOptionalRelated = {
        ...state.objectOptionalRelated,
        ...objectOptionalRelated,
      };
    },
    fetchTEObjectsFailure: (state, { payload }) => {
      state.objectsLoading = {
        ...state.objectsLoading,
        [payload.objectType]: false,
      };
    },
  },
});

export default slice.reducer;

const {
  fetctMappingRequest,
  fetctMappingSuccess,
  fetctMappingFailure,

  fetchReservationModesRequest,
  fetchReservationModesFailure,
  fetchReservationModesSuccess,

  fetchReservationFieldsRequest,
  fetchReservationFieldsFailure,
  fetchReservationFieldsSuccess,

  fetchExamConfigFromApiRequest,
  fetchExamConfigFromApiSuccess,
  fetchExamConfigFromApiFailure,

  fetchTEObjectsRequest,
  fetchTEObjectsSuccess,
  fetchTEObjectsFailure,
} = slice.actions;

// ACTIONS
export const fetchMapping = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetctMappingRequest());

    const [fieldsByKindResponse, mappingResponse] = await Promise.all([
      api.get({
        endpoint: `${configService().REACT_APP_REST_API_URL}v1/apps/TE_PREFERENCES/integration/objects/fields-by-kinds`,
        data: {
          kinds: ['BOOLEAN', 'CATEGORY'],
        },
      }),
      api.getMapping(),
    ]);

    dispatch(
      fetctMappingSuccess({
        fieldsByKind: fieldsByKindResponse,
        mapping: mappingResponse,
      }),
    );
  } catch (e) {
    dispatch(fetctMappingFailure());
    console.error(e);
  }
};

export const fetchTEObjects =
  (objectType: string, settings: { withInactiveObjects?: boolean } = {}) =>
  async (dispatch: Dispatch, getState: () => any) => {
    const storeState = getState();
    const { withInactiveObjects } = settings;
    const relationship = storeState.objectRelationships.mappedRelationship;
    if (storeState.integration.objects[objectType] || storeState.integration.objectsLoading[objectType]) return;
    try {
      const queue = [];
      dispatch(fetchTEObjectsRequest({ objectType }));
      queue.push(
        api.get({
          endpoint: `${
            configService().REACT_APP_REST_API_URL
          }v1/apps/TE_PREFERENCES/integration/objects/types/${objectType}/extend?getAll=true`,
          data: {
            relatedObjectTypes: relationship[objectType],
            optionalRelatedObjectTypes: relationship[objectType],
          },
        }),
      );
      if (withInactiveObjects) {
        queue.push(
          api.get({
            endpoint: `${
              configService().REACT_APP_REST_API_URL
            }v1/apps/TE_PREFERENCES/integration/objects/types/${objectType}/extend?getAll=true`,
            data: {
              relatedObjectTypes: relationship[objectType],
              optionalRelatedObjectTypes: relationship[objectType],
              active: false,
            },
          }),
        );
      }
      const responses = await Promise.all(queue);
      dispatch(
        fetchTEObjectsSuccess({
          type: objectType,
          objects: responses[0]?.data,
          inactiveObjects: responses[1]?.data,
        }),
      );
    } catch (e) {
      dispatch(fetchTEObjectsFailure({ objectType }));
      console.error(e);
    }
  };

export const fetchReservationModes = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchReservationModesRequest());
    const res = await api.post({
      successMessage: false,
      endpoint: `${configService().REACT_APP_BASE_URL}/reservation-modes/find`,
    });
    dispatch(fetchReservationModesSuccess(res));
  } catch (e) {
    dispatch(fetchReservationModesFailure());
    console.error(e);
  }
};

export const fetchExamConfigFromApi = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchExamConfigFromApiRequest());
    const res = await api.get({
      successMessage: false,
      endpoint: `${configService().REACT_APP_REST_API_URL}v1/apps/seat-allocation-settings`, // Will most likely be renamed
    });
    dispatch(fetchExamConfigFromApiSuccess(res));
  } catch (e) {
    dispatch(fetchExamConfigFromApiFailure());
    console.error(e);
  }
};

export const fetchReservationFields = (mode: string) => async (dispatch: Dispatch, getState: () => any) => {
  try {
    const storeState = getState();
    const reservationMode = storeState.integration.reservationModes?.find(
      ({ extId }: TReservationMode) => extId === mode,
    );
    if (storeState.integration.reservationFields[mode]) return;
    dispatch(fetchReservationFieldsRequest());
    const reservationTemplates = await api.get({
      endpoint: `${configService().REACT_APP_BASE_URL}/reservation-templates`,
      data: {
        reservationMode: reservationMode?.id,
      },
    });

    const allFieldIds = reservationTemplates.results.flatMap(({ fields }: any) => fields);
    const allFields = await TEObjectManager.getFieldsById(allFieldIds);
    const relatedObjectTypesResponse = await Promise.all(
      reservationTemplates.results.map((reservationTemplate: TReservationTemplate) =>
        api.findTypes({
          reservationTemplate: reservationTemplate.id,
        }),
      ),
    );

    const reservationObjectTypes = relatedObjectTypesResponse.flatMap((item) =>
      item.results.map(({ extId }: TTEObject) => extId),
    );
    TEObjectManager.init({ objectTypes: reservationObjectTypes });

    dispatch(
      fetchReservationFieldsSuccess({
        fields: Object.values(allFields),
        mode,
        reservationTemplates: reservationTemplates.results,
        reservationObjectTypes,
      }),
    );
  } catch (e) {
    dispatch(fetchReservationFieldsFailure());
    console.error(e);
  }
};

/* SELECTORs */
export const TEObjectSelector = (type?: string) => (state: ApplicationState) => {
  if (!type) return [];
  return (state.integration.objects[type] as any[]) || [];
};
export const TEInactiveObjectIdsSelector = (type?: string) => (state: ApplicationState) => {
  if (!type) return [];
  return (state.integration.inactiveObjects[type] as any[]) || [];
};
export const TEObjectLoadingSelector = () => (state: ApplicationState) => {
  return state.integration.objectsLoading;
};

export const TEFieldsByKindSelector = () => (state: ApplicationState) => {
  return state.integration.fieldsByKind;
};

export const TEProgramObjectSelector = () => (state: ApplicationState) => {
  let programObject;
  for (const key in state.integration.mapping?.objectTypes) {
    if (state.integration.mapping?.objectTypes[key]?.applicationObjectTypeGroup === 'PROGRAM') {
      programObject = state.integration.mapping.objectTypes[key];
      break;
    }
  }
  return programObject;
};

export const mappingLoadingSelector = (state: ApplicationState) => {
  return state.integration.mappingLoading;
};

export const TEObjectMappingSelector = () => (state: ApplicationState) => {
  return state.integration.mapping;
};
export const TEObjectLabeledFieldSelector = () => (state: ApplicationState) => {
  return state.integration.mapping?.labeledFields;
};
export const TEObjectFieldLabelsMappingSelector = () => (state: ApplicationState) => {
  return state.integration.mapping?.fieldLabelsMapping;
};
export const reservationModesSelector =
  () =>
  (state: ApplicationState): TReservationMode[] => {
    return state.integration.reservationModes;
  };
export const reservationFieldsSelector = (mode: string) => (state: ApplicationState) => {
  return state.integration.reservationFields[mode];
};
export const reservationFieldsLoadingSelector = (state: ApplicationState) => state.integration.reservationFieldsLoading;

export const reservationObjectTypesSelector = (mode: string) => (state: ApplicationState) => {
  return state.integration.reservationObjectTypes[mode] || [];
};
