/* eslint-disable no-param-reassign */
import { createSlice, Dispatch } from '@reduxjs/toolkit';
import * as activityService from 'activities/services/activities.service';
import { IAuthState, IDefaultReducerState } from 'types/state.interface';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import { TActivityResultsInResponse } from '../types/activity.type';
import { ApplicationState } from 'slices';
import { TTemplateReservationField } from '../types/importTemplate.type';
import { TField } from '@timeedit/types/lib/types';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';

export interface ActivityState {
  activities: TActivityResultsInResponse[];
  allActivitySeriesIds: string[];
  selectedIds?: string[];
  trigger: number;
  pagination: {
    page: number;
    perPage: number;
    totalActivities: number;
  };
  filter: {
    status: string;
  };
  loadings: {
    fetch: IDefaultReducerState;
    delete: IDefaultReducerState;
    send: IDefaultReducerState;
  };
  groupBy: TActivityGroupBy;
}

export const initialState: ActivityState = {
  activities: [],
  allActivitySeriesIds: [],
  selectedIds: [],
  pagination: {
    page: 1,
    perPage: 100,
    totalActivities: 1,
  },
  filter: {
    status: '',
  },
  trigger: 0,
  loadings: {
    fetch: {
      loading: false,
      hasErrors: false,
    },
    delete: {
      loading: false,
      hasErrors: false,
    },
    send: {
      loading: false,
      hasErrors: false,
    },
  },
  groupBy: 'ACTIVITY_SERIES',
};

const allocationSlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    fetchActivitiesRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.fetch);
    },
    fetchActivitiesFailure: (state: ActivityState) => {
      finishedLoadingFailure(state.loadings.fetch);
    },
    fetchActivitiesSuccess: (state: ActivityState, { payload }) => {
      const { results, totalResults, allKeys } = payload;
      finishedLoadingSuccess(state.loadings.fetch);
      state.activities = results;
      state.allActivitySeriesIds = allKeys;
      state.pagination.totalActivities = totalResults;
    },

    publishActivitiesRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.send);
    },
    publishActivitiesFailure: (state: ActivityState) => {
      finishedLoadingFailure(state.loadings.send);
    },
    publishActivitiesSuccess: (state: ActivityState, { payload }) => {
      state.trigger += 1;
      state.selectedIds = undefined;
      finishedLoadingSuccess(state.loadings.send);
    },

    deleteActivitiesRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.delete);
    },
    deleteActivitiesFailure: (state: ActivityState) => {
      finishedLoadingFailure(state.loadings.delete);
    },
    deleteActivitiesSuccess: (state: ActivityState, { payload }) => {
      state.trigger += 1;
      state.selectedIds = undefined;
      finishedLoadingSuccess(state.loadings.delete);
    },

    changeTablePagination: (
      state: ActivityState,
      { payload: { page, perPage } }: { payload: { page?: number; perPage?: number } },
    ) => {
      if (page !== undefined) {
        state.pagination.page = page;
      }
      if (perPage !== undefined) {
        state.pagination.perPage = perPage;
      }
    },

    changeFilterValue: (state: ActivityState, { payload }) => {
      state.filter = payload;
    },

    changeSelectedActivityIds: (state: ActivityState, { payload }: { payload: string[] }) => {
      state.selectedIds = payload;
    },

    changeActivityGroupBy: (state: ActivityState, { payload }: { payload: TActivityGroupBy }) => {
      state.groupBy = payload;
    },

    triggerToFetchActivities: (state: ActivityState) => {
      state.trigger += 1;
    },
  },
});

export const {
  fetchActivitiesRequest,
  fetchActivitiesFailure,
  fetchActivitiesSuccess,

  publishActivitiesRequest,
  publishActivitiesFailure,
  publishActivitiesSuccess,

  deleteActivitiesRequest,
  deleteActivitiesFailure,
  deleteActivitiesSuccess,

  changeTablePagination,
  changeSelectedActivityIds,
  triggerToFetchActivities,
  changeFilterValue,
  changeActivityGroupBy,
} = allocationSlice.actions;

export type TActivityGroupBy = keyof typeof EActivityGroupings;

export const fetchActivities =
  (showDMStatusFilter = false, groupBy: TActivityGroupBy = 'ACTIVITY_SERIES') =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId) return;
      dispatch(fetchActivitiesRequest());
      const filter = storeState.activity?.filter;
      const ssp = {
        groupBy,
        matchType: 'ALL',
        page: storeState.activity?.pagination.page,
        limit: storeState.activity?.pagination.perPage,
        filters: showDMStatusFilter
          ? {
              status: { values: [EActivityStatus.CREATED, EActivityStatus.IN_REVIEW, EActivityStatus.SUBMITTED] },
            }
          : {},
      };
      if (showDMStatusFilter && filter?.status) {
        ssp.filters = {
          status: { values: [filter.status as EActivityStatus] },
        };
      }
      const response = await activityService.getActivities(organizationId, ssp);
      dispatch(fetchActivitiesSuccess(response));
    } catch (e) {
      dispatch(fetchActivitiesFailure());
      console.error(e);
    }
  };

export const publishActivities =
  (activitySeriesIds?: string[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId || !activitySeriesIds) return null;
      dispatch(publishActivitiesRequest());
      const response = await activityService.publishActivities(organizationId, activitySeriesIds);
      dispatch(publishActivitiesSuccess(response));
      return response;
    } catch (e) {
      dispatch(publishActivitiesFailure());
      console.error(e);
      return e;
    }
  };

export const deleteActivities =
  (activitySeriesIds?: string[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId || !activitySeriesIds) return null;
      dispatch(deleteActivitiesRequest());
      const response = await activityService.deleteActivities(organizationId, activitySeriesIds);
      dispatch(deleteActivitiesSuccess(response));
      return response;
    } catch (e) {
      dispatch(deleteActivitiesFailure());
      console.error(e);
      return e;
    }
  };

export default allocationSlice.reducer;

// SELECTORS
export const activitiesSelector = (state: { activity: ActivityState }): TActivityResultsInResponse[] =>
  state.activity.activities;
export const allActivitySeriesIdsSelector = (state: { activity: ActivityState }): string[] =>
  state.activity.allActivitySeriesIds;
export const selectedActivitiesSelector = (state: { activity: ActivityState }) => state.activity.selectedIds;
export const activitiesLoadingSelector = (state: { activity: ActivityState }) => state.activity.loadings.fetch.loading;
export const activitiesSendingSelector = (state: { activity: ActivityState }) => state.activity.loadings.send.loading;
export const activitiesDeletingSelector = (state: { activity: ActivityState }) =>
  state.activity.loadings.delete.loading;
export const activitiesPaginationSelector = (state: { activity: ActivityState }) => state.activity.pagination;
export const activitiesTriggerSelector = (state: { activity: ActivityState }) => state.activity.trigger;

export const activityGroupBySelector = (state: { activity: ActivityState }) => state.activity.groupBy;

export const activityFiltersSelector = (state: { activity: ActivityState }) => state.activity.filter;

export const reservationFieldsForTemplateSelector =
  (mode: string) =>
  (state: ApplicationState): TTemplateReservationField[] => {
    const reservationFields = state.integration.reservationFields[mode];
    if (!reservationFields) return [];
    return reservationFields?.map((field: TField) => {
      return {
        ...field,
        label: field.name,
        excludeInScheduling: false,
        valueType: 'STRING',
      };
    });
  };
