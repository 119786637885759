/* eslint-disable no-param-reassign */
import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { sanitizeRecursive } from '@timeedit/preferences-and-dm-commons/lib/src/utils';
import { isLoadingRequest, finishedLoadingSuccess, finishedLoadingFailure } from '../../utils/sliceHelpers';
import api from '../../services/api.service';
import { isEqual, keyBy, pick, uniq } from 'lodash';
import { TEObjectSelector } from '../../slices/integration.slice';

import { configService } from '../../services/config.service';

import {
  DataManagerObjectReviewStatus,
  MongoSortOrder,
  ReviewByStudyCombinationSortableField,
  ReviewByCourseQuery,
  pathwayStatuses,
  ReviewByCourseSortableField,
  StudentSetGenerationStatusString,
} from '@timeedit/types/lib/constants';
import { replaceStringInArray } from '../utils/replaceInArray';
import { PathwayObject } from '@timeedit/types/lib/types';
import { TCreatePathwayPayload, TPathway, TPathwayConfig } from '../pages/PathwayService.type';
import { GENERATING } from '../pages/PathwayServiceConstants';
import { TSubmitReplaceModalPayload } from '../pages/components/ModalReplaceCourse';
import { toTPathway } from '../pages/PathwayService.utils';
import { generateGetPathwaysQuery } from '../pages/usePathwayWatchers';
import { paginationDefaults } from 'constants/table.constants';
import { TExpectedEnrollmentHarmonizationReturnSuccess } from '@timeedit/preferences-and-dm-commons/lib/src';

type TReviewStatusCounts = Record<DataManagerObjectReviewStatus, number>;

export type TStudentSetGenerationTrigger = {
  triggerNumberValue: number;
  onlyCounts: boolean;
};

const initialState = {
  forms: [],
  filters: {
    pathwayStatus: [pathwayStatuses.ACCEPTED, pathwayStatuses.NEW, pathwayStatuses.EDITED],
  } as Record<string, any>,
  reviewByCourseFilters: {},
  pathways: [],
  coursesTotal: 0,
  dataManagerObjectsCount: undefined,

  fetchPathwaysTrigger: 1, // Number of decimals decide about what to do: 0 decimals = fetch everything, one decimal (like 1.1): Fetch "onlyCounts"
  studentSetGenerationTrigger: {
    triggerNumberValue: 0,
    onlyCounts: false,
  } as TStudentSetGenerationTrigger,

  loadings: {
    pathways: false,
    forms: false,
  },
  loading: false,
  reviewStudyCombinationsPage: paginationDefaults.reviewStudyCombinationsPage,
  reviewStudyCombinationsPerPage: paginationDefaults.reviewStudyCombinationsPerPage,
  reviewStudyCombinationsSortBy: undefined as unknown as ReviewByStudyCombinationSortableField,
  reviewStudyCombinationsSortOrder: undefined as unknown as MongoSortOrder,
  reviewByCourseQuery: {
    page: paginationDefaults.reviewByCoursePage,
    perPage: paginationDefaults.reviewByCoursePerPage,
  } as ReviewByCourseQuery,
  reviewStatusCounts: {} as TReviewStatusCounts,
};

export const RETURN_EMPTY_COURSE_LIST_BECAUSE_NO_COURSE_SELECTED =
  'RETURN_EMPTY_COURSE_LIST_BECAUSE_NO_COURSE_SELECTED';

// Reducers
const slice = createSlice({
  name: 'pathway',
  initialState,
  reducers: {
    fetctPathwayFormsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetctPathwayFormsSuccess: (state: any, { payload }) => {
      state.forms = payload || [];
      finishedLoadingSuccess(state);
    },
    fetctPathwayFormsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },

    fetchPathwaysRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchPathwaysSuccess: (state: any, { payload }) => {
      const { dataManagerObjects, countPerAllPages, reviewStatusCounts, onlyCounts, allIds } = payload;
      if (!onlyCounts) {
        const pathways: TPathway[] = dataManagerObjects
          .filter((item: any) => item.inputObject.inputType === 'pathway')
          .map(toTPathway);
        state.pathways = pathways;
        state.allPathwayIds = allIds;
      }
      state.countPerAllPages = countPerAllPages;
      state.reviewStatusCounts = reviewStatusCounts;
      finishedLoadingSuccess(state);
    },
    fetchPathwaysFailure: (state: any) => {
      finishedLoadingFailure(state);
    },

    createPathwayRequest: () => {},
    createPathwaySuccess: (state: any) => {
      state.fetchPathwaysTrigger = (parseInt(state.fetchPathwaysTrigger, 10) + 1).toFixed(0);
    },
    createPathwayFailure: (state: any) => {},

    editPathwayRequest: () => {},
    editPathwaySuccess: (state: any) => {
      state.fetchPathwaysTrigger = (parseInt(state.fetchPathwaysTrigger, 10) + 1).toFixed(0);
    },
    editPathwayFailure: (state: any) => {},

    deletePathwaySuccess: (state: any) => {
      state.fetchPathwaysTrigger = (parseInt(state.fetchPathwaysTrigger, 10) + 1).toFixed(0);
    },

    fetchDataManagerObjectCountRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchDataManagerObjectCountSuccess: (state: any, { payload }) => {
      const { dataManagerObjectsCount } = payload;
      state.dataManagerObjectsCount = dataManagerObjectsCount;
      finishedLoadingSuccess(state);
    },
    fetchDataManagerObjectCountFailure: (state: any) => {
      finishedLoadingFailure(state);
    },

    fetchCoursesCountRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchCoursesCountSuccess: (state: any, { payload }: { payload: { coursesCount: number } }) => {
      const { coursesCount } = payload;
      state.coursesTotal = coursesCount;
      finishedLoadingSuccess(state);
    },
    fetchCoursesCountFailure: (state: any) => {
      finishedLoadingFailure(state);
    },

    changePathwayStatusRequest: (state: any) => {
      isLoadingRequest(state);
    },
    changePathwayStatusSuccess: (state: any, { payload }) => {
      finishedLoadingSuccess(state);
      const { pathwayIds, status } = payload;
      state.fetchPathwaysTrigger = (parseInt(state.fetchPathwaysTrigger, 10) + 1).toFixed(1);
      state.pathways = state.pathways.map((pathway: TPathway) => {
        if (!pathwayIds.includes(pathway._id)) return pathway;
        return {
          ...pathway,
          reviewStatus: status,
        };
      });
    },
    changePathwayStatusFailure: (state: any) => {
      finishedLoadingFailure(state);
    },

    updateMultiplePathwaysRequest: (state: any) => {},
    updateMultiplePathwaysSuccess: (state: any) => {
      finishedLoadingSuccess(state);
      state.fetchPathwaysTrigger = (parseInt(state.fetchPathwaysTrigger, 10) + 1).toFixed(0);
    },
    updateMultiplePathwaysFailure: (state: any) => {
      finishedLoadingFailure(state);
    },

    updateNumberOfStudentOfFormInstanceRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateNumberOfStudentOfFormInstanceSuccess: (state: any) => {
      finishedLoadingSuccess(state);
      state.fetchPathwaysTrigger = (parseInt(state.fetchPathwaysTrigger, 10) + 1).toFixed(0);
    },
    updateNumberOfStudentOfFormInstanceFailure: (state: any) => {
      finishedLoadingFailure(state);
    },

    changeGenerateStudentSetsStatusSuccess: (
      state: any,
      {
        payload,
      }: {
        payload: {
          dataManagerObjectIds: string[];
          newStatus?: StudentSetGenerationStatusString;
        };
      },
    ) => {
      finishedLoadingSuccess(state);
      const { dataManagerObjectIds, newStatus } = payload;
      state.pathways = state.pathways.map((pathway: TPathway) => {
        if (!dataManagerObjectIds.includes(pathway._id)) return pathway;
        return {
          ...pathway,
          studentSetGenerationStatusString: newStatus || GENERATING,
          // TODO: Also adapt studentSetGenerationStatus!
        };
      });
      state.studentSetGenerationTrigger = {
        onlyCounts: true,
        triggerNumberValue: state.studentSetGenerationTrigger.triggerNumberValue + 1,
      };
    },

    resetStudentSetGenerationTrigger: (state) => {
      state.studentSetGenerationTrigger = initialState.studentSetGenerationTrigger;
    },

    triggerToReloadStudenSetGeneration: (state) => {
      state.studentSetGenerationTrigger.triggerNumberValue += 1;
    },

    triggerToReloadPathways: (state: any) => {
      state.fetchPathwaysTrigger = (parseInt(state.fetchPathwaysTrigger, 10) + 1).toFixed(0);
    },

    updateFilters: (state, { payload }) => {
      state.filters = payload;
    },

    updateReviewByCourseFilters: (state, { payload }) => {
      state.filters = { ...state.filters, formId: payload.formId };
      state.reviewByCourseFilters = payload;
    },

    reviewStudyCombinationsChangePagination: (
      state,
      {
        payload,
      }: {
        payload: {
          reviewStudyCombinationsPage: number;
          reviewStudyCombinationsPerPage: number;
        };
      },
    ) => {
      state.reviewStudyCombinationsPage = payload.reviewStudyCombinationsPage;
      state.reviewStudyCombinationsPerPage = payload.reviewStudyCombinationsPerPage;
    },

    updateReviewByStudyCombinationsSorting: (
      state,
      {
        payload,
      }: {
        payload: {
          reviewStudyCombinationsSortBy: ReviewByStudyCombinationSortableField;
          reviewStudyCombinationsSortOrder: MongoSortOrder;
        };
      },
    ) => {
      state.reviewStudyCombinationsSortBy = payload.reviewStudyCombinationsSortBy;
      state.reviewStudyCombinationsSortOrder = payload.reviewStudyCombinationsSortOrder;
    },

    updateReviewByCourseQuery: (state, { payload }: { payload: ReviewByCourseQuery }) => {
      state.reviewByCourseQuery = payload;
    },

    updateReviewByCourseQueryPagination: (state, { payload }: { payload: { page: number; pageSize: number } }) => {
      const { page, pageSize } = payload;
      state.reviewByCourseQuery.page = page;
      state.reviewByCourseQuery.perPage = pageSize;
    },

    updateReviewByCourseQuerySorting: (
      state,
      { payload }: { payload: { sortBy: ReviewByCourseSortableField; sortDirection: MongoSortOrder } },
    ) => {
      const { sortBy, sortDirection } = payload;
      state.reviewByCourseQuery.sortBy = sortBy;
      state.reviewByCourseQuery.sortDirection = sortDirection;
    },

    // This is the only case where we need to change stuff in an useEffect. Hence, the separate function
    updateReviewByCourseQueryCourseExtIds: (state, { payload }: { payload: string[] }) => {
      state.reviewByCourseQuery.courseExtId = payload;
    },

    fetchCoursesForReviewByCourseRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchCoursesForReviewByCourseSuccess: (
      state: any,
      { payload }: { payload: { coursesForReviewByCourse: unknown[]; countPerAllPages: number } },
    ) => {
      const { coursesForReviewByCourse, countPerAllPages } = payload;
      state.coursesForReviewByCourse = coursesForReviewByCourse;
      state.countPerAllPagesForReviewByCourse = countPerAllPages;
      finishedLoadingSuccess(state);
    },
    fetchCoursesForReviewByCourseFailure: (state: any) => {
      finishedLoadingFailure(state);
    },

    harmonizeEnrollmentDiffsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    harmonizeEnrollmentDiffsSuccess: (
      state: any,
      { payload }: { payload: TExpectedEnrollmentHarmonizationReturnSuccess },
    ) => {
      finishedLoadingSuccess(state);
      state.fetchPathwaysTrigger = (parseInt(state.fetchPathwaysTrigger, 10) + 1).toFixed(0); // Trigger reload
      return state;
    },
    harmonizeEnrollmentDiffsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

const {
  fetctPathwayFormsRequest,
  fetctPathwayFormsSuccess,
  fetctPathwayFormsFailure,

  fetchPathwaysRequest,
  fetchPathwaysSuccess,
  fetchPathwaysFailure,

  createPathwayRequest,
  createPathwaySuccess,
  createPathwayFailure,

  editPathwayRequest,
  editPathwaySuccess,
  editPathwayFailure,

  deletePathwaySuccess,

  fetchDataManagerObjectCountRequest,
  fetchDataManagerObjectCountSuccess,
  fetchDataManagerObjectCountFailure,

  fetchCoursesCountRequest,
  fetchCoursesCountSuccess,
  fetchCoursesCountFailure,

  harmonizeEnrollmentDiffsRequest,
  harmonizeEnrollmentDiffsSuccess,
  harmonizeEnrollmentDiffsFailure,

  changePathwayStatusFailure,
  changePathwayStatusRequest,
  changePathwayStatusSuccess,

  updateMultiplePathwaysRequest,
  updateMultiplePathwaysSuccess,
  updateMultiplePathwaysFailure,

  updateNumberOfStudentOfFormInstanceRequest,
  updateNumberOfStudentOfFormInstanceSuccess,
  updateNumberOfStudentOfFormInstanceFailure,

  changeGenerateStudentSetsStatusSuccess,

  reviewStudyCombinationsChangePagination,

  updateFilters,

  updateReviewByCourseFilters,

  resetStudentSetGenerationTrigger,

  updateReviewByCourseQuery,
  updateReviewByCourseQueryCourseExtIds,
  updateReviewByCourseQueryPagination,
  updateReviewByCourseQuerySorting,

  updateReviewByStudyCombinationsSorting,

  fetchCoursesForReviewByCourseRequest,
  fetchCoursesForReviewByCourseSuccess,
  fetchCoursesForReviewByCourseFailure,

  triggerToReloadStudenSetGeneration,
  triggerToReloadPathways,
} = slice.actions;

export {
  reviewStudyCombinationsChangePagination,
  updateReviewByStudyCombinationsSorting,
  updateFilters,
  updateReviewByCourseFilters,
  resetStudentSetGenerationTrigger,
  updateReviewByCourseQuery,
  updateReviewByCourseQueryPagination,
  updateReviewByCourseQuerySorting,
  updateReviewByCourseQueryCourseExtIds,
  changeGenerateStudentSetsStatusSuccess,
  triggerToReloadStudenSetGeneration,
};

// Actions
export const fetchPathwayForms = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetctPathwayFormsRequest());
    const response = await api.get({
      endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/pathway-config-from-user`,
    });
    dispatch(fetctPathwayFormsSuccess(response?.data?.pathwayConfigFromUser));
  } catch (e) {
    dispatch(fetctPathwayFormsFailure());
    console.error(e);
  }
};

export const createNewPathway =
  ({ mandatoryCourseIds, electiveCourseIds, expectedEnrollment, scopedObject, formId, name }: TCreatePathwayPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(createPathwayRequest());
      await api.post({
        endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/data-manager-objects/create-input-object-as-user`,
        data: sanitizeRecursive({
          inputType: 'pathway',
          inputContent: {
            mandatoryCourseIds,
            electiveCourseIds,
            expectedEnrollment,
            programExtId: scopedObject,
            pathwayConfigFromUserId: formId,
            name,
          },
        }),
      });
      dispatch(createPathwaySuccess());
    } catch (e) {
      dispatch(createPathwayFailure());
      console.error(e);
    }
  };

export const editExistingPathway =
  (
    { mandatoryCourseIds, electiveCourseIds, expectedEnrollment, scopedObject, formId }: TCreatePathwayPayload,
    dataManagerObjectId: string,
  ) =>
  async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch(editPathwayRequest());
      const storeState = getState();
      const currentObject = storeState.pathway.pathways.find(
        (pathway: TPathway) => pathway._id === dataManagerObjectId,
      );
      const isEdited =
        currentObject &&
        currentObject.status !== 'NEW' &&
        (!isEqual(electiveCourseIds, currentObject.electiveCourseIds) ||
          !isEqual(mandatoryCourseIds, currentObject.mandatoryCourseIds));
      await api.put({
        endpoint: `${
          configService().REACT_APP_PATHWAY_SERVICE_URL
        }v1/data-manager-objects/edit-input-object/${dataManagerObjectId}`,
        data: sanitizeRecursive({
          inputType: 'pathway',
          inputContent: {
            mandatoryCourseIds,
            electiveCourseIds,
            expectedEnrollment,
            programExtId: scopedObject,
            pathwayConfigFromUserId: formId,
            status: isEdited ? 'EDITED' : undefined,
          },
        }),
      });
      dispatch(editPathwaySuccess());
    } catch (e) {
      dispatch(editPathwayFailure());
      console.error(e);
    }
  };

export const fetchFormPathways =
  (
    formId: string,
    query: Record<string, unknown>,
    page: number,
    perPage: number,
    sortBy?: ReviewByStudyCombinationSortableField,
    sortDirection?: MongoSortOrder,
    onlyCounts?: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(fetchPathwaysRequest());
      const res = await api.post({
        endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/data-manager-objects`,
        data: sanitizeRecursive({
          pathwayConfigFromUserId: formId,
          page,
          perPage,
          sortBy,
          sortDirection,
          onlyCounts,
          ...query,
        }),
        successMessage: false,
      });
      dispatch(fetchPathwaysSuccess({ ...res.data, formId, onlyCounts }));
    } catch (e) {
      dispatch(fetchPathwaysFailure());
      console.error(e);
    }
  };

export const fetchFormPathwaysWithoutUpdatingState = (query: Record<string, unknown>) => {
  return api.post({
    endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/data-manager-objects`,
    data: sanitizeRecursive({
      ...query,
    }),
    successMessage: false,
  });
};

export const fetchCoursesWithoutUpdatingState = (courseExtId: string[], pathwayConfigFromUserId?: string) => {
  return api.post({
    endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/review-by-course`,
    data: sanitizeRecursive({
      courseExtId,
      page: 1,
      perPage: 100,
      pathwayConfigFromUserId,
    }),
    successMessage: false,
  });
};

export const fetchDataManagerObjectCount = (formId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchDataManagerObjectCountRequest());

    const res = await api.get({
      endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/data-manager-objects/count`,
      data: {
        pathwayConfigFromUserId: formId,
      },
    });
    dispatch(fetchDataManagerObjectCountSuccess({ ...res.data, formId }));
  } catch (e) {
    dispatch(fetchDataManagerObjectCountFailure());
    console.error(e);
  }
};

export const fetchCoursesCount = (formId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchCoursesCountRequest());
    const res = await api.get({
      endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/review-by-course/count`,
      data: {
        pathwayConfigFromUserId: formId,
      },
    });
    dispatch(fetchCoursesCountSuccess({ ...res.data, formId }));
  } catch (e) {
    dispatch(fetchCoursesCountFailure());
    console.error(e);
  }
};

export const fetchCoursesForReviewByCourse = (data: ReviewByCourseQuery) => async (dispatch: Dispatch) => {
  if (data.courseExtId?.length === 1 && data.courseExtId[0] === RETURN_EMPTY_COURSE_LIST_BECAUSE_NO_COURSE_SELECTED) {
    return dispatch(fetchCoursesForReviewByCourseSuccess({ coursesForReviewByCourse: [], countPerAllPages: 0 }));
  }

  try {
    dispatch(fetchCoursesForReviewByCourseRequest());
    const res = await api.post({
      endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/review-by-course`,
      data: sanitizeRecursive(data),
      successMessage: false,
    });
    return dispatch(fetchCoursesForReviewByCourseSuccess({ ...res.data }));
  } catch (e) {
    dispatch(fetchCoursesForReviewByCourseFailure());
    return console.error(e);
  }
};

export const harmonizeEnrollmentDiffs = (data: ReviewByCourseQuery) => async (dispatch: Dispatch) => {
  try {
    dispatch(harmonizeEnrollmentDiffsRequest());
    const res = await api.post({
      endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/review-by-course/harmonize-enrollment-diffs`,
      data: sanitizeRecursive(data),
      successMessage: false,
    });
    return dispatch(harmonizeEnrollmentDiffsSuccess({ ...res.data }));
  } catch (e) {
    dispatch(harmonizeEnrollmentDiffsFailure());
    return console.error(e);
  }
};

export default slice.reducer;

// Selectors
export const pathwayFormsSelector = (state: any) => state.pathway.forms as any[];
export const pathwayFiltersSelector = (state: any) => state.pathway.filters as any;
export const reviewByCourseFiltersSelector = (state: any) => ({
  ...state.pathway.reviewByCourseFilters,
  formId: state.pathway.filters.formId,
});
export const reviewByCourseQuerySelector = (state: any) => state.pathway.reviewByCourseQuery as ReviewByCourseQuery;

export const pathwaySelector = () => (state: any) => {
  return state.pathway.pathways;
};

export const allPathwayIdsSelector = () => (state: any) => {
  return state.pathway.allPathwayIds;
};

export const pathwayCountPerAllPagesSelector = () => (state: any) => {
  return state.pathway.countPerAllPages;
};

export const pathwayReviewStatusCountSelector =
  () =>
  (state: any): undefined | TReviewStatusCounts => {
    return state.pathway.reviewStatusCounts;
  };

export const coursesForReviewByCourseSelector = () => (state: any) => {
  return state.pathway.coursesForReviewByCourse;
};
export const countPerAllPagesForReviewByCourseSelector = () => (state: any) => {
  return state.pathway.countPerAllPagesForReviewByCourse;
};

export const pathwayCoursesTotalSelector = () => (state: any) => state.pathway.coursesTotal;
export const dataManagerObjectCountSelector = (state: any): undefined | number => {
  return state.pathway.dataManagerObjectsCount;
};
export const pathwayPrimaryObjectsSelector = (state: any) => {
  const selectedForm = pathwaySelectedFormSelector(state);
  if (!selectedForm) return null;
  return TEObjectSelector(selectedForm?.objectScope)(state);
};

export const pathwaySelectedFormSelector = (state: any): undefined | TPathwayConfig => {
  const form = state.pathway.forms.find((form: any) => form._id === state.pathway.filters.formId);
  if (!form) return undefined;
  let programObject;
  if (!state.integration && !state.integration.mapping) return undefined;
  for (const key in state.integration.mapping?.objectTypes) {
    if (state.integration.mapping?.objectTypes[key]?.applicationObjectTypeGroup === 'PROGRAM') {
      programObject = state.integration.mapping.objectTypes[key];
      break;
    }
  }
  return {
    ...form,
    objectScope: programObject?.objectTypeExtId || 'program',
  };
};

export const paginationCurrentPageSelector = (state: any) => {
  return state.pathway.reviewStudyCombinationsPage;
};

export const pathwayCoursesSelector = (state: any) => {
  const selectedForm = pathwaySelectedFormSelector(state);
  if (!selectedForm) return [];
  return TEObjectSelector(selectedForm?.pathwaysSettings.course.datasource)(state);
};

export const pathwayReviewByStudyCombinationPaginationSelector = (state: any) =>
  ({
    reviewStudyCombinationsPage: state.pathway.reviewStudyCombinationsPage,
    reviewStudyCombinationsPerPage: state.pathway.reviewStudyCombinationsPerPage,
  }) as {
    reviewStudyCombinationsPage: number;
    reviewStudyCombinationsPerPage: number;
  };

export const pathwayReviewByStudyCombinationSortingSelector = (state: any) =>
  ({
    reviewStudyCombinationsSortBy: state.pathway.reviewStudyCombinationsSortBy,
    reviewStudyCombinationsSortOrder: state.pathway.reviewStudyCombinationsSortOrder,
  }) as {
    reviewStudyCombinationsSortBy: ReviewByStudyCombinationSortableField;
    reviewStudyCombinationsSortOrder: MongoSortOrder;
  };

export const loadingSelector = (state: any) => state.pathway.loading;

export const changePathwayStatus =
  (pathwayIds: string[], status: DataManagerObjectReviewStatus) => async (dispatch: Dispatch) => {
    try {
      dispatch(changePathwayStatusRequest());
      await api.post({
        endpoint: `${
          configService().REACT_APP_PATHWAY_SERVICE_URL
        }v1/data-manager-objects/approve-or-reject-or-delete/multiple`,
        data: sanitizeRecursive({
          inputObjects: pathwayIds.map((pathwayId) => {
            return {
              _id: pathwayId,
              inputContent: {
                reviewStatus: status,
              },
            };
          }),
        }),
      });
      dispatch(changePathwayStatusSuccess({ pathwayIds, status }));
    } catch (e) {
      dispatch(changePathwayStatusFailure());
      console.error(e);
    }
  };

export const changeAllPathwayStatus =
  (action: DataManagerObjectReviewStatus) => async (dispatch: Dispatch, getState: () => any) => {
    try {
      const state = getState();
      const { filters } = state.pathway;
      const res = await api.post({
        endpoint: `${
          configService().REACT_APP_PATHWAY_SERVICE_URL
        }v1/data-manager-objects/approve-or-reject-or-delete/all/${action}`,
        data: {
          ...generateGetPathwaysQuery(filters),
          withCourseData: undefined,
          pathwayConfigFromUserId: filters.formId,
        },
      });
      dispatch(triggerToReloadPathways());
      return res;
    } catch (e) {
      return e;
    }
  };

export const updatePathwayCourse =
  (courseId: string, data: TSubmitReplaceModalPayload['data']) => async (dispatch: Dispatch, getState: any) => {
    if (!courseId) return;
    const state = getState();
    const pathways = (state.pathway.coursesForReviewByCourse as { pathways: PathwayObject[] }[]).reduce(
      (all, current) => [...all, ...current.pathways],
      [] as PathwayObject[],
    );
    const indexedPathways = keyBy(pathways, '_id');
    dispatch(updateMultiplePathwaysRequest());
    try {
      const pathwaysToUpdate = [
        ...(data.mandatory || [])
          .filter((pathwayId) => !!indexedPathways[pathwayId])
          .map((pathwayId) => ({
            _id: pathwayId,
            inputObject: {
              inputType: 'pathway',
              inputContent: {
                ...pick(indexedPathways[pathwayId], ['expectedEnrollment', 'programExtId', 'electiveCourseIds']),
                mandatoryCourseIds: uniq(
                  replaceStringInArray(indexedPathways[pathwayId].mandatoryCourseIds, courseId, data.selectedCourse),
                ),
              },
            },
          })),
        ...(data.elective || [])
          .filter((pathwayId) => !!indexedPathways[pathwayId])
          .map((pathwayId) => ({
            _id: pathwayId,
            inputObject: {
              inputType: 'pathway',
              inputContent: {
                ...pick(indexedPathways[pathwayId], ['expectedEnrollment', 'programExtId', 'mandatoryCourseIds']),
                electiveCourseIds: uniq(
                  replaceStringInArray(indexedPathways[pathwayId].electiveCourseIds, courseId, data.selectedCourse),
                ),
              },
            },
          })),
      ];

      const pathwayConfigFromUserId = state.pathway.filters.formId;
      const pathwaysToUpdateEnriched = pathwaysToUpdate.map((p) => ({
        ...p,
        inputObject: { ...p.inputObject, inputContent: { ...p.inputObject.inputContent, pathwayConfigFromUserId } },
      }));

      await api.put({
        endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/data-manager-objects/edit-input-object-multiple`,
        data: sanitizeRecursive({
          inputObjects: pathwaysToUpdateEnriched,
        }),
      });
      dispatch(updateMultiplePathwaysSuccess());
      fetchCoursesCount(pathwayConfigFromUserId)(dispatch);
    } catch (e) {
      dispatch(updateMultiplePathwaysFailure());
      console.error(e);
    }
  };

export const updateMultiplePathways = (pathways: TPathway[]) => async (dispatch: Dispatch) => {
  dispatch(updateMultiplePathwaysRequest());

  try {
    const pathwaysToUpdate = pathways.map((pathway: TPathway) => {
      return {
        _id: pathway._id,
        inputObject: {
          inputType: 'pathway',
          inputContent: {
            ...pick(pathway, [
              'programExtId',
              'pathwayConfigFromUserId',
              'electiveCourseIds',
              'mandatoryCourseIds',
              'expectedEnrollment',
            ]),
          },
        },
      };
    });
    await api.put({
      endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/data-manager-objects/edit-input-object-multiple`,
      data: sanitizeRecursive({
        inputObjects: pathwaysToUpdate,
      }),
    });
    dispatch(updateMultiplePathwaysSuccess());
  } catch (e) {
    dispatch(updateMultiplePathwaysFailure());
    console.error(e);
  }
};

export const updateCourseExpectedEnrollment =
  ({ courseId, numberOfStudents }: { courseId: string; numberOfStudents: number }) =>
  async (dispatch: Dispatch, getState: () => any) => {
    try {
      dispatch(updateNumberOfStudentOfFormInstanceRequest());
      const storeState = getState();
      const {
        pathway: { filters, forms },
      } = storeState;
      const form = forms?.find((form: any) => form._id === filters?.formId);
      await api.put({
        endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/data-manager-objects/courses/expected-enrollment`,
        data: {
          courseId,
          value: numberOfStudents,
          field: form?.pathwaysSettings?.course.fields.expectedEnrollment,
        },
      });
      dispatch(updateNumberOfStudentOfFormInstanceSuccess());
    } catch (e) {
      dispatch(updateNumberOfStudentOfFormInstanceFailure());
      console.error(e);
    }
  };

export const deletePathways = (pathwayIds: string[]) => async (dispatch: Dispatch) => {
  try {
    dispatch(changePathwayStatusRequest());
    await api.post({
      endpoint: `${
        configService().REACT_APP_PATHWAY_SERVICE_URL
      }v1/data-manager-objects/approve-or-reject-or-delete/multiple`,
      data: sanitizeRecursive({
        inputObjects: pathwayIds.map((pathwayId) => {
          return {
            _id: pathwayId,
            inputContent: {
              reviewStatus: 'DELETED',
            },
          };
        }),
      }),
    });
    dispatch(changePathwayStatusSuccess({ pathwayIds }));
    dispatch(deletePathwaySuccess());
  } catch (e) {
    dispatch(changePathwayStatusFailure());
    console.error(e);
  }
};

export const pathwayMandatoryCoursesOfSelectedProgram =
  (scopedObject: string) =>
  (state: any): string[] => {
    const related = state.integration.objectRelated[scopedObject] || [];
    const optionalRelated = state.integration.objectOptionalRelated[scopedObject] || [];
    return related.filter((id: string) => !optionalRelated.includes(id));
  };

export const pathwayElectiveCoursesOfSelectedProgram =
  (scopedObject: string) =>
  (state: any): string[] => {
    const optionalRelated = state.integration.objectOptionalRelated[scopedObject] || [];
    const related = state.integration.objectRelated[scopedObject] || [];
    return optionalRelated.filter((id: string) => !related.includes(id));
  };

export const programAllCourseRelations = (state: {
  integration: { objectOptionalRelated: Record<string, string[]>; objectRelated: Record<string, string[]> };
}): Record<string, string[]> => {
  const output: Record<string, string[]> = {};
  for (const obj in state.integration.objectOptionalRelated) {
    if (state.integration.objectOptionalRelated[obj].length > 0) {
      output[obj] = state.integration.objectOptionalRelated[obj];
    }
  }
  for (const obj in state.integration.objectRelated) {
    if (state.integration.objectRelated[obj].length > 0) {
      output[obj] = [...(output[obj] || []), ...state.integration.objectRelated[obj]];
    }
  }
  return output;
};

export const allRelationsSelector = (state: {
  integration: { objectOptionalRelated: Record<string, string[]>; objectRelated: Record<string, string[]> };
}): { objectOptionalRelated: Record<string, string[]>; objectRelated: Record<string, string[]> } => {
  return {
    objectRelated: state.integration.objectRelated,
    objectOptionalRelated: state.integration.objectOptionalRelated,
  };
};

export const pathwaysFetchingTrigger = () => (state: any) => state.pathway.fetchPathwaysTrigger;

export const studentSetGenerationTriggerSelector =
  () =>
  (state: any): TStudentSetGenerationTrigger =>
    state.pathway.studentSetGenerationTrigger;
