/* eslint-disable camelcase */
import React from 'react';
import { Tag } from 'antd';
import { isArray, isEmpty, keyBy, sumBy, intersection } from 'lodash';
import { TPathway, TPathwayConfig, TPathwayRow } from './PathwayService.type';
import { PATHWAY_STATUSES_MAPPING } from './PathwayService.constants';
import { TEObject } from '@timeedit/ui-components/lib/src/types/TEObject.type';
import { TEditingExpectedEnrollment } from './Views/Tabs/ReviewByCourse';
import { TMapping } from 'types/integration.type';
import intl from 'i18n/intl';

const language = intl.messages;

export const buildHistEnrolLookup = (pathwayObjects: TEObject[], historicalField?: string) => {
  const indexedPathways = keyBy(pathwayObjects, 'te_extid');

  const histEnrolLookup: Record<number, string[]> = {};
  for (const l in indexedPathways) {
    const currentPathway = indexedPathways[l] as unknown as Record<string, number>;

    if (!historicalField || !currentPathway[historicalField]) {
      continue;
    }

    if (!histEnrolLookup[currentPathway[historicalField]]) {
      histEnrolLookup[currentPathway[historicalField]] = [l];
    } else {
      histEnrolLookup[currentPathway[historicalField]].push(l);
    }
  }

  return histEnrolLookup;
};

export const convertToPathwayRowData = (
  pathways: TPathway[],
  dependencies: {
    [key: string]: TEObject[];
  },
  fieldSettings: {
    labeledCourseField?: string;
    labeledPathwayField?: string;
    creditField?: string;
    historicalField?: string;
    pathwayDatasource?: string;
    courseDatasource?: string;
  },
): TPathwayRow[] => {
  const { courses, pathwayObjects } = dependencies;
  const indexedCourses = keyBy(courses, 'te_extid');
  const indexedPathways = keyBy(pathwayObjects, 'te_extid');

  const result = pathways?.map((pathway) => {
    const {
      mandatoryCourseIds = [],
      electiveCourseIds = [],
      mandatoryCourseData = [],
      electiveCourseData = [],
    } = pathway;
    const allCourses: TEObject[] = [...mandatoryCourseIds, ...electiveCourseIds]
      .filter((courseId) => indexedCourses[courseId])
      .map((courseId) => indexedCourses[courseId]);
    const { labeledCourseField = '', creditField = '', historicalField = '', labeledPathwayField = '' } = fieldSettings;
    const reviewStatus = pathway.reviewStatus || 'NOT_REVIEWED';
    const mappedCourseData = keyBy([...mandatoryCourseData, ...electiveCourseData], 'scopedObject');

    const historicalEnrollmentNumber = Number(indexedPathways[pathway.pathwayExtId || '']?.[historicalField]);
    return {
      ...pathway,
      id: pathway._id,
      name: pathway.name || indexedPathways[pathway.pathwayExtId || '']?.[labeledPathwayField],
      historicalEnrollment: Number.isNaN(historicalEnrollmentNumber) ? ('-' as const) : historicalEnrollmentNumber,
      courses: allCourses.map((course) => ({
        ...course,
        label: course[labeledCourseField],
        value: course.te_extid,
        numberOfStudents: mappedCourseData[course.te_extid]?.numberOfStudents,
        formInstanceId: mappedCourseData[course.te_extid]?.formInstanceId,
        error: mappedCourseData[course.te_extid]?.error,
      })),
      rawCourses: allCourses,
      credits: creditCalculator(allCourses, creditField),
      coursesLabel: allCourses.map((course) => course[labeledCourseField] || course.te_extid).join(', '),
      reviewStatusLabel: (
        <Tag color={PATHWAY_STATUSES_MAPPING[reviewStatus]?.type}>{PATHWAY_STATUSES_MAPPING[reviewStatus]?.label}</Tag>
      ),
      fieldSettings,
    };
  });
  return result;
};

export const filterObjectsByCategorizedFields = (
  objects: null | Record<string, any>[],
  filters: Record<string, any>,
) => {
  if (!objects || isEmpty(filters)) return null;
  const fields = Object.keys(filters).filter((k) => {
    if (!isArray(filters[k])) {
      return true;
    }
    return filters[k]?.length > 0;
  });
  if (isEmpty(fields)) return null;
  return objects
    .filter((obj) => {
      return fields
        .filter((field) => !isEmpty(filters[field]))
        .every((field) => {
          const items = filters[field];
          if (!items) {
            return true;
          }
          if (Array.isArray(obj[field]) && !isEmpty(intersection(items, obj[field]))) {
            return true;
          }
          if (typeof obj[field] === 'string' && items.includes(obj[field])) {
            return true;
          }
          return false;
        });
    })
    .map(({ te_extid }) => te_extid);
};

export const filterByNumber = (
  filter: { GREATER_THAN?: null | number; LESS_THAN?: null | number },
  number?: number | '-',
) => {
  if (isEmpty(filter)) return true;
  if (number === '-') return false;

  const { GREATER_THAN = null, LESS_THAN = null } = { ...filter };

  if (GREATER_THAN === null && LESS_THAN === null) return true;
  if (number === undefined) return false;

  if (LESS_THAN === null && GREATER_THAN !== null) {
    return number > GREATER_THAN;
  }
  if (GREATER_THAN === null && LESS_THAN !== null) return number < LESS_THAN;

  if (LESS_THAN !== null && GREATER_THAN !== null) {
    if (GREATER_THAN > LESS_THAN) return true;
    return GREATER_THAN < number && number < LESS_THAN;
  }
  return true;
};
export const creditCalculator = (courses: TEObject[], creditsField: string) => {
  return sumBy(courses, (course) => {
    return Number.isNaN(course[creditsField]) ? 0 : Number(course[creditsField]);
  });
};
export const pathwayIsAcceptedOrRejected = (status?: string) => !!status && ['KEEP', 'REJECTED'].includes(status);

export const pathwayIsFinished = (allocationStatus?: string): boolean =>
  !!allocationStatus && ['STUDENT_SETS_ALLOCATED', 'STUDENT_SETS_GENERATED'].includes(allocationStatus);

export const toTPathway = (pathway: Partial<TPathway>): TPathway => {
  const inputContent = pathway.inputObject?.inputContent || {};
  return {
    ...inputContent,
    _id: pathway._id,
    reviewStatus: pathway.reviewStatus,
    formInstanceStatus: inputContent.status,
    studentSetGenerationStatus: pathway?.studentSetGenerationStatus,
    studentSetGenerationStatusString: pathway?.studentSetGenerationStatusString,
    disabled: pathwayIsFinished(pathway.studentSetGenerationStatusString),
  };
};

export const getChangedPahtwaysAfterEdit = (
  pathways: TPathway[],
  editingExpectedEnrollment: TEditingExpectedEnrollment,
  otherOptions: Partial<TPathway>,
): undefined | TPathway[] => {
  if (isEmpty(editingExpectedEnrollment)) return undefined;
  const filteredPathways = pathways.filter((pathway) => !pathwayIsFinished(pathway.studentSetGenerationStatusString));
  if (isEmpty(filteredPathways)) return undefined;

  const hasChange = filteredPathways.some(
    (pathway: any) => editingExpectedEnrollment[pathway._id] !== pathway.expectedEnrollment,
  );
  if (!hasChange) return undefined;

  return filteredPathways
    .filter((pathway) => editingExpectedEnrollment[pathway._id] !== undefined)
    .map((pathway: any) => ({
      ...pathway,
      expectedEnrollment: editingExpectedEnrollment[pathway._id],
      ...otherOptions,
    }));
};

export const getCustomColumnLabelsBasedOnMappingAndForm = (
  mapping: TMapping | undefined,
  form: TPathwayConfig | undefined,
) => {
  if (!mapping || !form) return {};
  const { pathwaysSettings } = form;
  const { course, pathways } = pathwaysSettings;
  const courseLabel = mapping.objectTypes?.[course.datasource]?.applicationObjectTypeLabel;
  const pathwaysLabel = mapping.objectTypes?.[pathways.datasource]?.applicationObjectTypeLabel;
  const approvedStudyCombinationsLabel = pathwaysLabel ? `${language.approved} ${pathwaysLabel}` : '';
  const countStudyCombinationsLabel = pathwaysLabel ? `${language.count} ${pathwaysLabel}` : '';
  const historicalEnrollmentLabel =
    mapping.fieldLabelsMapping?.[pathways.datasource][pathways.enrollment.historicalEnrollment];
  const expectedEnrollmentLabel =
    mapping.fieldLabelsMapping?.[pathways.datasource][pathways.enrollment?.expectedEnrollment];
  const expectedEnrollmentStudyCombinationLabel =
    pathwaysLabel && expectedEnrollmentLabel ? `${expectedEnrollmentLabel} ${pathwaysLabel}` : '';

  const expectedEnrollmentInsideCourseObjLabel =
    mapping.fieldLabelsMapping?.[course.datasource][course.fields?.expectedEnrollment || ''];
  const expectedEnrollmentCourseLabel =
    courseLabel && expectedEnrollmentInsideCourseObjLabel
      ? `${expectedEnrollmentInsideCourseObjLabel} ${courseLabel}`
      : '';

  const creditsLabel = mapping.fieldLabelsMapping?.[course.datasource][course.fields?.credits];

  return {
    pathwaysLabel,
    courseLabel,
    approvedStudyCombinationsLabel,
    countStudyCombinationsLabel,
    historicalEnrollmentLabel,
    expectedEnrollmentLabel,
    expectedEnrollmentStudyCombinationLabel,
    expectedEnrollmentCourseLabel,
    creditsLabel,
  };
};
